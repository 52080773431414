import {
  handlerSendRequestWithController,
  handlerSendRequest,
  handlerSendArrayRequest,
  changeConfigElementsResponseView,
  checkProfileScriptsCompilationRresponse
} from './handlerSendRequest';

class ConfigsApi {
  getConfig = ({ projectid, environment, elementName, namespace,  query }) =>
    handlerSendRequestWithController(`get_${elementName}s`, { projectid, environment, namespace, query})

  addElement = ({ projectid, environment, elementName, fields, successUpdateTime }) =>
    handlerSendRequest(`add_${elementName}`, { projectid, environment, fields, successUpdateTime })

  removeElement = ({ projectid, environment, elementName, id, hash, successUpdateTime }) =>
    handlerSendRequest(`remove_${elementName}`, { projectid, environment, id, hash, successUpdateTime })

  changeElement = ({ projectid, environment, elementName, id, hash, fields, successUpdateTime }) =>
    handlerSendRequest(`change_${elementName}`, { projectid, environment, id, hash, fields, successUpdateTime })

  getConfigList = ({ projectid, environment, elementName, groupByNamespace }) =>
    handlerSendRequest(`get_${elementName}_list`, {
      projectid,
      environment,
      group_by_namespace: groupByNamespace
    });

  setConfigList = ({ projectid, environment, elementName, config }) =>
    handlerSendRequest(`set_${elementName}_list`, { projectid, environment, config });

  getScriptsConfig = ({ projectid, environment, elementName }) =>
    handlerSendRequest(`download_${elementName}s_archive`, { projectid, environment });

  setScriptsConfig = ({ projectid, environment, archive, elementName }) =>
    handlerSendRequest(`upload_${elementName}s_archive`, { projectid, environment, archive });

  getNamespaceList = ({ projectid, environment, elementName }) =>
    handlerSendRequest(`get_${elementName}_namespace_list`, { projectid, environment });

  getScriptsArchiveInfo = ({ projectid, environment, elementName }) =>
    handlerSendRequest(`get_${elementName}s_archive_info`, { projectid, environment });

  saveAll = ({ changedFields }) =>
    handlerSendArrayRequest(changedFields, changeConfigElementsResponseView);

  removeAll = ({ changedFields }) =>
    handlerSendArrayRequest(changedFields, changeConfigElementsResponseView);

  getIdentList = ({ projectid, environment, elementName }) =>
    handlerSendRequest(`get_${elementName}_ident_list`, { projectid, environment });

  downloadConfigJS = ({ projectid, environment, elementName }) =>
    handlerSendRequest(`download_${elementName}s`, { projectid, environment });

  uploadConfigListJS = ({ config, projectid, environment, elementName }) =>
    handlerSendRequest(`upload_${elementName}s`, { projectid, environment, data: config });

  checkScriptsCompilation = ({ projectid, environment }) =>
    handlerSendRequest(
      `check_profile_scripts_compilation`,
      { projectid, environment },
      checkProfileScriptsCompilationRresponse
    );
}

export default new ConfigsApi();