import { handlerSendRequest } from './handlerSendRequest';

class ServicesInfoApi {
  getServicesInfo = ({ projectid, environment }) =>
    handlerSendRequest('get_services_info', { projectid, environment })

  getPortalServerVersion = () =>
    handlerSendRequest('get_version')

  registerConfigs = ({ projectid, environment }) =>
    handlerSendRequest('register_configs', { projectid, environment })
}

export default new ServicesInfoApi();