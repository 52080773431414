import { flow, makeObservable, observable, action } from "mobx";
import { GamesStore } from ".";
import servicesInfo from "../api/servicesInfo";

class ServicesInfoStore {
  constructor() {
    this.servicesInfo = null;
    this.serverVersion = "";

    makeObservable(this, {
      servicesInfo: observable,
      loadServicesInfo: flow.bound,
      clearServicesInfo: action.bound,
      loadPortalServerVersion: flow.bound,
      serverVersion: observable,
      registerConfigs: flow.bound
    });
  }

  *loadServicesInfo() {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield servicesInfo.getServicesInfo({ projectid, environment });
      this.servicesInfo = data || {};
    } catch (e) {
      this.servicesInfo = null;
    }
  }

  *loadPortalServerVersion() {
    try {
      const { data } = yield servicesInfo.getPortalServerVersion();
      this.serverVersion = data
    } catch (e) {
      this.servicesInfo = null;
    }
  }

  *registerConfigs() {
    const { projectid, environment } = GamesStore;
    try {
      yield servicesInfo.registerConfigs({ projectid, environment });
    } catch (e) {

    }
  }

  clearServicesInfo() {
    this.servicesInfo = null;
  }
}

export default new ServicesInfoStore();