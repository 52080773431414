import { AuthStore } from "../stores";
import Http from "./Http";
import { v4 as uuidv4 } from 'uuid';
import { omit } from "lodash";

class Rpc {
  getAbortController(abortController) {
    return abortController || new AbortController()
  }

  sendRequest = (action, data = {}, abortController) => {
    const { user } = AuthStore;
    if (user?.token && action !== "signIn")
      data.jwt = user.token
    const id = uuidv4();

    const controller = this.getAbortController(abortController)

    return Http.makeInstance().post(
      "",
      {
        "jsonrpc": "2.0",
        "id": id,
        "method": action,
        "params": data
      },
      {
        signal: controller.signal
      }
    )
  }

  sendArrayRequest = (data = []) => {
    const { user } = AuthStore;
    let token;
    if (user?.token) token = user.token;

    const requestData = data.map(requestData => {
      const id = uuidv4();
      return  {
        "method": requestData.action,
        "jsonrpc": "2.0",
        "id": requestData.id || requestData.key || id,
        "params": {...omit(requestData, 'action', 'key'), jwt: token}
      }
    });

    return Http.makeInstance().post(
      "",
      requestData
    )
  }
}

export default new Rpc()