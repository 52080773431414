import { Box, Heading, Skeleton } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { GamesStore, LeaderboardStore, MenuPageSelectorsStore } from "../../../stores";
import LeaderboardTable from "./LeaderboardTable";
import Permissions from "../../Permissions";
import LeaderboardFilterType from "./LeaderboardFilterType";
import { DEFAULT_ELEMENTS, DEFAULT_PAGE_INDEX, DEFAULT_TAB_NAME } from "./constants";
import { isEmpty, isUndefined, keys } from "lodash";

const Leaderboard = observer(() => {
  const {
    currentPageSelectorIndex,
    clearPageSelector,
    setNameForAddingNewPageSelector,
    setCalculatablePageSelectorWidth,
    setAddNewPageSelectorWithModal,
    setHandleRemovePageSelector,
    setHeaderPageSelector,
    setIsAddPageSelectorDisable,
    setHintForDisableAdd
  } = MenuPageSelectorsStore;
  const [ elementsCount, setElementsCount ] = useState(DEFAULT_ELEMENTS);
  const { projectid, environment } = GamesStore;
  const {
    leaderboard,
    loadLeaderboard,
    clearLeaderboard,
    lbPageDescription,
    removelbPage,
    loadlbPageDescription,
    currentlbPageData,
    currentleaderboardDescription,
    setlbPageDescriptionByIndex,
    clearLeaderboardAndDescription,
    sortBy,
    loadLeaderboardDescription,
    description,
    clearlbPageDescription
  } = LeaderboardStore;

  const [ startIndex, setStartIndex ] = useState(DEFAULT_PAGE_INDEX);
  const [ isLoading, setIsLoading ] = useState(false);

  const leaderboardFiltersRef = useRef();

  const changePage = useCallback((pageIndex) => {
    if (lbPageDescription && currentlbPageData) {
      currentlbPageData.pageIndex = pageIndex;
      setlbPageDescriptionByIndex(currentPageSelectorIndex, currentlbPageData);
    }
    setStartIndex(pageIndex);
  }, [lbPageDescription, currentPageSelectorIndex, currentlbPageData, setlbPageDescriptionByIndex]);

  useEffect(() => {
    clearPageSelector();
    loadlbPageDescription(DEFAULT_TAB_NAME);
  }, [projectid, environment, clearPageSelector, loadlbPageDescription]);

  useEffect(() => {
    setHandleRemovePageSelector(removelbPage);
    setNameForAddingNewPageSelector(DEFAULT_TAB_NAME, "leaderboard", () => {
      clearLeaderboard();
      leaderboardFiltersRef.current.resetForm();
    });
    setHeaderPageSelector('leaderboard item');
    setCalculatablePageSelectorWidth(true);
    setAddNewPageSelectorWithModal(false);
  }, [
    setAddNewPageSelectorWithModal,
    setCalculatablePageSelectorWidth,
    setNameForAddingNewPageSelector,
    removelbPage,
    setHandleRemovePageSelector,
    projectid,
    clearLeaderboard,
    setHeaderPageSelector
  ]);

  useEffect(() => {
    return () => {
     clearPageSelector();
     clearlbPageDescription();
    }
  }, [clearPageSelector, clearlbPageDescription]);

  useEffect(() => {
    clearLeaderboardAndDescription();
  }, [clearLeaderboardAndDescription, projectid, environment])

  const totalLeaderboard = leaderboard?.info?.total || 0;

  const fetchData = useCallback(
    ({ field, desc, currentleaderboardDescription }) => {
      if (currentleaderboardDescription && keys(currentleaderboardDescription).length) {
        const count = Number(currentleaderboardDescription.count);
        const currentIndex = startIndex * count;
        const start = desc || isUndefined(desc) ? currentIndex : (-currentIndex -1);
        setIsLoading(true);
        loadLeaderboard({
          start,
          category: field,
          ...currentleaderboardDescription,
          count
        }).then(() => {
          setIsLoading(false);
        })
      }
    },
    [loadLeaderboard, startIndex]
  );

  useEffect(() => {
    const { id: field, desc } = sortBy[0] || {};
    if (currentleaderboardDescription) {
      fetchData({ field, desc, currentleaderboardDescription })
    }
  }, [sortBy, fetchData, currentleaderboardDescription]);

  useEffect(() => {
    if (currentlbPageData) {
      setStartIndex(currentlbPageData.pageIndex || DEFAULT_PAGE_INDEX);
      setElementsCount(currentlbPageData.leaderboardDescription.count || DEFAULT_ELEMENTS);
    }
  }, [currentlbPageData]);

  useEffect(() => {
    loadLeaderboardDescription().then((res) =>{
      const isEmptyDescription = isEmpty(res);
      setIsAddPageSelectorDisable(isEmptyDescription);
      setHintForDisableAdd(!isEmptyDescription ? 'Fill new leaderboard table to add next' : '');
    });
  }, [loadLeaderboardDescription, projectid, environment, setIsAddPageSelectorDisable, setHintForDisableAdd]);

  const [hiddenColumns, setHiddenColumnsBySelect] = useState([]);

  useEffect(() => {
    setHiddenColumnsBySelect(currentlbPageData?.hiddenColumns || []);
  }, [currentlbPageData]);

  return (
    <Permissions
      permission="can_view_leaderboard"
      isLoaded={leaderboard !== null || currentleaderboardDescription !== null || description !== null}
    >
      <Heading as="h3" size="lg">
        Leaderboard
      </Heading>

      <LeaderboardFilterType />
      <Skeleton isLoaded={leaderboard !== null || currentleaderboardDescription !== null} height="100%">
        <Box>
          <LeaderboardTable
            total={totalLeaderboard}
            startIndex={startIndex}
            elementsCount={elementsCount}
            isLoading={isLoading}
            changePage={changePage}
            category={leaderboard?.info?.category || ''}
            hiddenColumns={hiddenColumns}
            setHiddenColumnsBySelect={setHiddenColumnsBySelect}
          />
        </Box>
      </Skeleton>
    </Permissions>
  );
});

export default Leaderboard;
