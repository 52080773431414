import {
  Box,
  Divider,
  Skeleton,
  useDisclosure
} from "@chakra-ui/react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  ConfigsViewStore,
  ConfigsStore,
  FilterStore,
  GamesStore,
  MenuPageSelectorsStore,
  NotificationsStore,
  PermissionsStore,
  SchemaStore,
  ConfigElementStore
} from "../../../stores";
import Permissions from "../../Permissions";
import ConfigsTable from "./ConfigsTable";
import HeadingConfigButtons from "./HeadingConfigButtons";
import ChangeForSelectedModal from "./ChangeForSelectedModal";
import { formatCreateTime } from "src/helpers/formatCreateTime";
import { getNamespaceCurrentName } from "src/helpers/getNamespace";
import TextElementWithCopyButton from "src/components/TextElementWithCopyButton";
import { keys } from "lodash";
import './style.scss';

const UPDATE_CONFIG_MESSAGE = 'Сonfig was change by another user, please update page';
const ALL_NAMESPACES = 'All namespaces';

const Configs = observer((props) => {
  const {
    configsData,
    loadConfigs,
    clearConfigs,
    successUpdateTime,
    loadNamespaceList,
    isNeedUpdateConfigs
  } = ConfigsStore;
  const { projectid, environment, games_loaded } = GamesStore;
  const {
    setExpendedRows,
    loadFieledValueList,
    setIsUseNamespace,
    setConfigLang,
    clearConfigsChecked,
    setCsvEditable,
    putToStorage,
    getFromStorage
  } = ConfigsViewStore;
  const { clearAllErrorsAndChanges } = ConfigElementStore;
  const { setShowNotification, setHideNotification } = NotificationsStore;
  const { loadSchema, schema } = SchemaStore;
  const { permissions } = PermissionsStore;
  const { clearFilter } = FilterStore;

  const {
    elementName,
    headerName,
    specCompletions,
    tableFields,
    lang,
    isUseNamespace = true,
    csvEditable
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const {
    currentPageSelector,
    addPageSelector,
    clearPageSelector,
    setNameForAddingNewPageSelector,
    setHeaderPageSelector,
    setPageSelector
  } = MenuPageSelectorsStore;
  const [isOpenChangeForSelectedModal, setIsOpenChangeForSelectedModal] = useState(false);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [hiddenColumns, setHiddenColumnsBySelect] = useState([]);

  const abortControllerRef = useRef(new AbortController());

  const handlerUnmount = useCallback(() => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    clearPageSelector();
  }, [abortControllerRef, clearPageSelector])

  useEffect(() => {
    setExpendedRows({})
  }, [currentPageSelector, setExpendedRows, elementName]);

  useEffect(() => {
    setCsvEditable(csvEditable)
  }, [csvEditable, setCsvEditable])

  useEffect(() => {
     clearFilter()
  }, [currentPageSelector, clearFilter]);

  useEffect(() => {
    clearPageSelector();
    setIsLoaded(false);
    setConfigLang(lang);
    setIsUseNamespace(isUseNamespace);
    clearConfigs();
    return () => handlerUnmount();
  }, [
    elementName,
    clearPageSelector,
    lang,
    setConfigLang,
    clearConfigs,
    setIsUseNamespace,
    isUseNamespace,
    handlerUnmount
  ]);

  useEffect(() => {
    setIsLoaded(false);
    if (projectid && environment && games_loaded) {
      loadSchema(elementName);
    }
  }, [clearConfigs, loadSchema, elementName, projectid, environment, games_loaded]);

  const columns = useMemo(() => {
    const propertyKeys = schema && keys(schema?.properties);
    const columnsFromSchema = propertyKeys?.map(field => ({
      Header: field,
      accessor: field
    })) || [];

    return [
    {
      Header: "create_t",
      accessor: "create_t",
      Cell: ({value}) => (
        value
          ? <TextElementWithCopyButton
              textValue={formatCreateTime(value)}
              copyValue={value}
              label="Copy as timestamp"
            />
          : ''
      ),
      columnWidth: '125px',
      sortType: (rowA, rowB) => {
        const rowACreate_t = rowA.values.create_t;
        const rowBCreate_t = rowB.values.create_t;
        return rowACreate_t - rowBCreate_t
      }
    },
    {
      Header: "_id",
      accessor: "_id"
    },
    {
      Header: "key",
      accessor: "key"
    },
    ...columnsFromSchema
  ]}, [schema]);

  const data = computed(() => {
    return configsData
      ? configsData?.map(
        ( config ) => ({
          ...Object.fromEntries(tableFields
            ? tableFields.map( (fieldName) => [fieldName, config.data[fieldName]])
            : []
          ),
          _id: config.internal?._id,
          key: config.internal.key,
          create_t: config.internal.create_t
        })
      )
      : [];
  }).get();

  const handleLoadConfigs = useCallback((namespace, query) => {
    specCompletions?.forEach((completion) => {
      loadFieledValueList(completion.from, completion.path, completion.specSymbol)
    })
    loadConfigs(elementName, namespace, query, abortControllerRef).then(() => {
      setIsLoaded(true);
      clearConfigsChecked();
      clearAllErrorsAndChanges();
    });
  }, [loadConfigs, elementName, clearConfigsChecked, loadFieledValueList, specCompletions, clearAllErrorsAndChanges]);

  const handleNamespaceChange = useCallback((current) => {
    if (current) {
      putToStorage([elementName, 'namespace'], current.name)
      const namespaceName = getNamespaceCurrentName(current);
      handleLoadConfigs(namespaceName)
    }
  }, [elementName, putToStorage, handleLoadConfigs]);

  const createNamespace = useCallback((newNamespace) => {
    return {name: newNamespace, nameOverride: null}
  }, [])

  const handleAddNewNamespace = useCallback((name) =>
    handleNamespaceChange(createNamespace(name)),
  [createNamespace, handleNamespaceChange]);

  const setTabs = useCallback((list) => {
    clearPageSelector();
    setNameForAddingNewPageSelector(
      'config',
      null,
      handleAddNewNamespace,
      handleNamespaceChange
    );
    setHeaderPageSelector('namespace');
    addPageSelector(ALL_NAMESPACES, false, ALL_NAMESPACES, true, "");
    list.forEach((namespace, namespaceIndex) => {
      addPageSelector(namespace, false, namespace, namespaceIndex === 0);
    });
  }, [
    clearPageSelector,
    setNameForAddingNewPageSelector,
    addPageSelector,
    setHeaderPageSelector,
    handleNamespaceChange,
    handleAddNewNamespace
  ]);

  const loadFromConfigStorage = useCallback((list) => {
    const storagesNamespaces = getFromStorage([elementName, 'namespace'])
    if (!storagesNamespaces) {
      return null
    }

    if (storagesNamespaces === ALL_NAMESPACES) {
      setPageSelector(0);
      return ''
    }

    const defaultNamespaceIndex = list.indexOf(storagesNamespaces);
    if (defaultNamespaceIndex > -1) {
      // added 1 because use All namespaces with 0 index (before namespaces list)
      setPageSelector(defaultNamespaceIndex + 1);
      return storagesNamespaces
    }
  }, [setPageSelector, elementName, getFromStorage]);

  const isEnable = useMemo(() => {
    return permissions?.includes(`can_view_${elementName}s`)
  }, [permissions, elementName]);

  const loadWithNamespaces = useCallback(async () => {
    return loadNamespaceList(elementName).then((list) => {
      setTabs(list);
      return loadFromConfigStorage(list);
    })
  }, [elementName, loadNamespaceList, setTabs, loadFromConfigStorage])

  const reloadAll = useCallback(() => {
    clearFilter();
    if (isUseNamespace) {
      loadWithNamespaces().then(currentNamespace => {
        handleLoadConfigs(currentNamespace);
      });
    } else {
      handleLoadConfigs();
    }
  }, [isUseNamespace, loadWithNamespaces, handleLoadConfigs, clearFilter])

  const handleChangeForChecked = () => {
    setIsOpenChangeForSelectedModal(true)
  }

  useEffect(() => {
    if (successUpdateTime === 0) {
      const loadAction = isUseNamespace ? reloadAll : handleLoadConfigs;
      setShowNotification(
        UPDATE_CONFIG_MESSAGE,
        () => {
          loadAction();
          setHideNotification();
        }
      );
    }
  }, [
    successUpdateTime,
    setShowNotification,
    loadConfigs,
    setHideNotification,
    isUseNamespace,
    reloadAll,
    handleLoadConfigs
  ]);

  useEffect(() => {
    if (isEnable) {
      reloadAll();
    }
  }, [isEnable, reloadAll, projectid, environment, permissions]);

  useEffect(() => {
    if (isNeedUpdateConfigs) {
      reloadAll();
    }
  }, [isNeedUpdateConfigs, reloadAll]);

  const updateHiddenColumns=(result) => {
    putToStorage([elementName, 'hiddenColumns'], result)
    setHiddenColumnsBySelect(result);
  }

  useEffect(() => {
    const hiddenColumnsFromStorage = getFromStorage([elementName, 'hiddenColumns']);
    if (hiddenColumnsFromStorage) {
      setHiddenColumnsBySelect(hiddenColumnsFromStorage)
    } else {
      const defaultColumns = [...tableFields];
      setHiddenColumnsBySelect(
        columns.reduce((acc, { Header }) => {
          if (!defaultColumns.includes(Header)) {
            acc.push(Header)
          }
          return acc
        }, [])
      )
    }
  }, [columns, tableFields, getFromStorage, elementName]);


  return (
    <Permissions
      permission={`can_view_${elementName}s`}
      isLoaded={isLoaded && configsData !== null && schema !== null}
    >
      <HeadingConfigButtons
        elementName={elementName}
        headerName={headerName}
        handleChangeForChecked={handleChangeForChecked}
        load={isUseNamespace ? reloadAll : handleLoadConfigs}
        handleLoadConfigs={handleLoadConfigs}
        onToggle={onToggle}
        isOpen={isOpen}
        onClose={onClose}
        columns={columns}
        hiddenColumns={hiddenColumns}
        updateHiddenColumns={updateHiddenColumns}
      />
      <Divider orientation="horizontal" />
      <Skeleton isLoaded={configsData !== null}>
        <Box mt={3} mb={3}>
          <ConfigsTable
            columns={columns}
            data={data}
            schema={schema}
            elementName={elementName}
            specCompletionsPath={specCompletions?.map(el => el.path)}
            hiddenColumns={hiddenColumns}
            updateHiddenColumns={updateHiddenColumns}
          />
        </Box>
      </Skeleton>
      {isOpenChangeForSelectedModal
        ? <ChangeForSelectedModal
            isOpen={isOpenChangeForSelectedModal}
            setIsOpen={setIsOpenChangeForSelectedModal}
            currentPageSelector={currentPageSelector}
            elementName={elementName}
            handleLoadConfigs={handleLoadConfigs}
            schema={schema}
          />
        : null}
    </Permissions>
  );
})

export default Configs;