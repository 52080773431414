import { observer } from "mobx-react";
import { isUndefined } from "lodash";
import { AuthStore, GamesStore } from "src/stores";
import { GAIJIN_CENTRAL_URL, NO_GAME_WITH_PERMISSIONS_ERROR } from "src/constants";
import { Box, Center, Link } from "@chakra-ui/layout";
import Header from "../Header";

const Authorization = observer(({ children }) => {
  const gsea = window.GSEA || undefined;

  const onGseaChanged = () => {
    const { login, user } = AuthStore;
    if (!user && (gsea.status === 'ok' && gsea.jwt)) {
      login(gsea.jwt)
    }
  }

  if (!isUndefined(gsea)) {
    gsea.on('change', onGseaChanged);
  }

  if (isUndefined(gsea)) {
    window.location.reload()
  }

  const { getGamesError } = GamesStore;

  if (getGamesError?.message === NO_GAME_WITH_PERMISSIONS_ERROR || getGamesError) {
    return (
      <Box h="100%">
        <Header/>
        <Center h="calc(100vh - 56px)" flexDirection="column">
          <div>{ getGamesError?.data || getGamesError}</div>
          <div>Go to:
            <Link href={GAIJIN_CENTRAL_URL} ml={1} target="_blank" color='teal.500'>
              {GAIJIN_CENTRAL_URL}
            </Link>
          </div>
        </Center>
      </Box>
    )
  }

  return children
})

export default Authorization