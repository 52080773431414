import {
  Flex, Box, Accordion, AccordionItem,
  AccordionIcon, AccordionButton, AccordionPanel
} from '@chakra-ui/react';
import {
  Leaderboard,
  Calendar,
  User,
  CodeBracketsSquare,
  InfoEmpty,
  TerminalSimple,
  Archive,
  GitCompare
} from 'iconoir-react';
import { observer } from 'mobx-react';
import SidebarMenuItem from './SidebarMenuItem';
import { useEffect } from 'react';
import { GamesStore, PermissionsStore, SidebarMenuStore } from 'src/stores';
import { menuConfig } from './menuConfig';
import Storages from 'src/services/Storages';

const NAME_FOR_SIDEBAR_MENU_IN_STORAGES = "sidebar_menu";

const SidebarMenu = observer(({isCollapse}) => {
  const { getEnabledConfigs, enableConfigs } = SidebarMenuStore;
  const { environment, projectid, games_loaded } = GamesStore;
  const { permissions } = PermissionsStore;

  useEffect(() => {
    if (projectid && environment && games_loaded) {
      getEnabledConfigs()
    }
  }, [getEnabledConfigs, environment, projectid, games_loaded]);

  const storagesSidebarMenu = Storages.get(NAME_FOR_SIDEBAR_MENU_IN_STORAGES);

  return (
    <Flex direction="column" overflowY="auto" className='sidebar-menu-block'>
      {/*defaultIndex={[0]} makes the first accordion open by default*/}
      <Accordion
        allowToggle
        defaultIndex={[storagesSidebarMenu?.configs]}
        onChange={(index) => Storages.put(
          NAME_FOR_SIDEBAR_MENU_IN_STORAGES, {...storagesSidebarMenu,'configs': index}
        )}
      >
        <AccordionItem>
          <h2>
            <AccordionButton
              w="full"
              size="md"
              variant="ghost"
              colorScheme="gray"
              justifyContent="start"
              borderLeftWidth={4}
              iconSpacing="4"
              h="12"
              rounded="none"
            >
              <Box as="span" display='flex' justifyContent='space-between' flex='2' textAlign='left'>
                <Box as="span" display='flex' flex='1'>
                  <Box as={CodeBracketsSquare} color='gray.400' size="20" marginInlineEnd='1rem' />
                  {isCollapse ? '' : 'Edit configs'}
                </Box>
                <AccordionIcon />
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={1}>
            {enableConfigs?.map(el => {
              const configElement = menuConfig[el];
              return configElement
                ? <SidebarMenuItem
                  icon={configElement.icon}
                  title={configElement.title}
                  link={`/${el}`}
                  exact
                  transformIcon={configElement.transformIcon}
                  isCollapse={isCollapse}
                  isDisabled={!permissions.includes(`can_view_${el}`)}
                />
                : null
              })
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Accordion
        allowToggle
        defaultIndex={[storagesSidebarMenu?.deploy]}
        onChange={(index) => Storages.put(
          NAME_FOR_SIDEBAR_MENU_IN_STORAGES, {...Storages.get(NAME_FOR_SIDEBAR_MENU_IN_STORAGES),'deploy': index}
        )}
      >
        <AccordionItem>
          <h2>
            <AccordionButton
              w="full"
              size="md"
              variant="ghost"
              colorScheme="gray"
              justifyContent="start"
              borderLeftWidth={4}
              iconSpacing="4"
              h="12"
              rounded="none"
            >
              <Box as="span" display='flex' justifyContent='space-between' flex='2' textAlign='left'>
                <Box as="span" display='flex' flex='1'>
                  <Box as={Calendar} color='gray.400' size="20" marginInlineEnd='1rem' />
                  {isCollapse ? '' : 'Deploy configs'}
                </Box>
                <AccordionIcon />
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={1}>
            <SidebarMenuItem
              icon={TerminalSimple}
              title={"Tags"}
              link={`/deploy_tags`}
              exact
              isCollapse={isCollapse}
              isDisabled={!permissions.includes("can_view_tag_content")}
            />
             <SidebarMenuItem
              icon={Archive}
              title={"History"}
              link={`/deploy_history`}
              exact
              isCollapse={isCollapse}
              isDisabled={!permissions.includes("can_view_tag_content")}
            />
            <SidebarMenuItem
              icon={GitCompare}
              title={"Compare"}
              link={`/deploy_compare`}
              exact
              isCollapse={isCollapse}
              isDisabled={!permissions.includes("can_view_tag_content")}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <SidebarMenuItem
        icon={Leaderboard}
        title={"Leaderboard"}
        link={`/leaderboard`}
        exact
        isCollapse={isCollapse}
        isDisabled={!permissions.includes("can_view_leaderboard")}
      />
      <SidebarMenuItem
        icon={User}
        title={"User management"}
        link={`/userManagement`}
        exact
        isCollapse={isCollapse}
        isDisabled={!permissions.includes("can_read_user_info")}
      />
      <SidebarMenuItem
        icon={InfoEmpty}
        title={"Services Info"}
        link={`/servicesInfo`}
        exact
        isCollapse={isCollapse}
        isDisabled={!permissions.includes("can_view_services_info")}
      />
    </Flex>
  )
})

export default SidebarMenu