import {
  Button
 } from "@chakra-ui/react";
import { Filter } from "iconoir-react";
import { observer } from "mobx-react";
import { FilterStore } from "../../stores";

const ShowFilterButton = observer(() => {
  const { queryValue, setIsShowFilters, isShowFilters } = FilterStore;
  return (
    <Button
      colorScheme={!!queryValue ? "green" : "gray"}
      ml={1}
      onClick={() => setIsShowFilters(!isShowFilters)}
    >
      <Filter />
    </Button>
  );
})

export default ShowFilterButton;