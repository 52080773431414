import {
  Box, Button, Flex, Menu, MenuButton, MenuItemOption, MenuList,
  MenuOptionGroup, Skeleton, Text, useBreakpointValue, Badge
} from '@chakra-ui/react';
import { Check, NavArrowDown } from 'iconoir-react';
import { observer } from "mobx-react";
import { useEffect } from 'react';
import { AuthStore, ConfigsStore, GamesStore } from '../../stores';
import { handleEventWithChanges } from 'src/helpers/handleEventWithChanges';
import './style.scss';
import { TAG_COLORS_FROM_THEME } from 'src/constants';

const SidebarGames = observer(({isCollapse}) => {
  const { games_loaded, projectid, game_name, environment, games, getGames, setGame } = GamesStore;
  const { user } = AuthStore;
  const { isConfigChanges } = ConfigsStore;

  console.log(projectid, environment, game_name)

  useEffect(() => {
    getGames()
  }, [user, getGames]);

  const placement = useBreakpointValue({
    md: 'right-start',
    base: 'bottom-end',
  });

  const onProjectChange = (projectid, environment) => {
    handleEventWithChanges(isConfigChanges, () => setGame(projectid, environment))
  };

  return (
    <Box flex="1" w="100%">
      <Skeleton isLoaded={games_loaded}>
        <Menu placement={placement}>
          <MenuButton
            as={Button}
            h="16"
            w="full"
            rounded="none"
            justifyContent="space-between"
            rightIcon={<NavArrowDown />}
          >
            <Flex direction="column" alignItems="start" gap="1">
              <Text
                fontSize="x-small"
                color="gray.400"
                textTransform="uppercase"
              >
                Game
              </Text>
              <Text
                fontSize="md"
                w="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                textAlign="left"
              >
                {game_name ?? '-'}
              </Text>
              <Flex direction="row" alignItems="center" gap="3" overflow="hidden">
                {environment && !isCollapse && (
                  <Badge
                    variant='solid'
                    colorScheme={TAG_COLORS_FROM_THEME[environment]}
                  >
                    {environment}
                  </Badge>
                )}
                {projectid && !isCollapse && (
                  <Badge
                    variant='solid'
                    colorScheme='purple'
                    style={{textTransform: 'none'}}
                  >
                    {projectid}
                  </Badge>
                )}
              </Flex>
            </Flex>
          </MenuButton>

          <MenuList
            shadow="xl"
            className='menu-list-block'
          >
            <MenuOptionGroup
              type="radio"
              title="SELECT GAME"
              value={projectid+environment}
              defaultValue={projectid}
            >
              {games.map((p, i) => (
                <MenuItemOption
                  key={i}
                  value={p.projectid+p.environment}
                  icon={<Check />}
                  onClick={() => onProjectChange(p.projectid, p.environment)}
                >
                  {p.name}
                  <Badge
                    style={{marginLeft: '0.5rem'}}
                    variant='solid'
                    colorScheme={TAG_COLORS_FROM_THEME[p.environment]}
                  >
                    {p.environment}
                  </Badge>
                  <Badge
                    style={{marginLeft: '0.5rem', textTransform: 'none'}}
                    variant='solid'
                    colorScheme='purple'>{p.projectid}</Badge>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Skeleton>
    </Box>
  )
})

export default SidebarGames