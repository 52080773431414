import {
  AddDatabaseScript,
  Group,
  MinusSquare,
  NoLock,
  Settings,
  StatsSquareUp,
  TableRows,
  Wifi,
  EyeAlt
} from "iconoir-react";

export const menuConfig = {
  "stats": {
    title: "Stats",
    icon: StatsSquareUp
  },
  "unlocks": {
    title: "Unlock",
    icon: NoLock
  },
  "items": {
    title: "Items",
    icon: MinusSquare
  },
  "contacts": {
    title: "Contacts",
    icon: Group
  },
  "triggers": {
    title: "Triggers",
    icon: Wifi,
    transformIcon: "rotate(45deg)"
  },
  "modes": {
    title: "Modes",
    icon: Settings
  },
  "profile_scripts": {
    title: "Scripts",
    icon: AddDatabaseScript
  },
  "tables": {
    title: "Tables",
    icon: TableRows
  },
  "externals": {
    title: "Externals",
    icon: EyeAlt
  }
}