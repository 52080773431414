import { handlerSendRequest, getUserInfoHandler, handlerWithShowResponse } from './handlerSendRequest';

class UserManagementApi {
  getUserInfoAndServices = ({ projectid, environment, ...requestData}) =>
    handlerSendRequest('get_user_info_and_services', { projectid, environment, ...requestData }, getUserInfoHandler)

  getUserProfileForService = ({ projectid, environment, service, userid}) =>
    handlerSendRequest(`get_${service}_user_profile`, { projectid, environment, userid })

  getServiceApi = ({ projectid, environment, service, userid}) =>
    handlerSendRequest(`get_${service}_api`, { projectid, environment, userid })

  callServiceApi = ({ projectid, environment, service, userid, ...params}) =>
    handlerSendRequest(`call_${service}_api`, { projectid, environment, userid, ...params }, handlerWithShowResponse)

  getUserHistory = ({ projectid, environment, service, userid, count, startPos}) =>
    handlerSendRequest(`get_${service}_user_history`, { projectid, environment, userid, service, count, startPos })

  getUserHistoryDetails = ({ projectid, environment, service, userid, id}) =>
    handlerSendRequest(`get_${service}_history_entry_details`, { projectid, environment, userid, service, id })
}

export default new UserManagementApi();