import { observer } from "mobx-react";
import { Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { SaveFloppyDisk } from 'iconoir-react';
import { SingleConfigStore } from "src/stores";
import { useCallback, useEffect, useState } from "react";
import CodeEditor from "src/components/CodeEditor";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import { STATUS_GREEN_COLOR, STATUS_RED_COLOR } from "src/constants";
import { isEqual, keys } from "lodash";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";

const SingleConfigContent = observer(({ configData, schema, elementName }) => {
  const {
    changeSingleConfig,
    setIsSingleConfigChanged,
    isSingleConfigChanged
  } = SingleConfigStore;

  const [editorText, setEditorText] = useState("");

  useEffect(() => {
    setEditorText(getJSONstringify(configData))
  }, [configData]);

  const [errors, setErrors] = useState({});

  const saveChanges = () => {
    changeSingleConfig(JSON.parse(editorText), elementName);
  };

  const handleSetError = useCallback((_, value) => {
    if (!isEqual(value, errors)) {
      setErrors(value);
    }
  }, [errors]);

  const getRowColor = () => {
    if (keys(errors)?.length > 0) {
      return STATUS_RED_COLOR
    }
    if (isSingleConfigChanged) {
      return STATUS_GREEN_COLOR
    }
    return 'inherit'
  }

  return (
    <Table>
      <Tbody>
        <Tr
          bg={getRowColor()}
        >
          <Td>
            <Flex direction="row" alignItems="center" justifyContent="end" >
              <ButtonWithTooltip
                size='xs'
                colorScheme='green'
                marginRight='20px'
                isDisabled={!isSingleConfigChanged || keys(errors)?.length > 0}
                onClick={saveChanges}
                accessPermission={`can_change_${elementName}`}
              >
                <SaveFloppyDisk />
              </ButtonWithTooltip>
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <CodeEditor
              newData={editorText}
              data={configData}
              schema={schema}
              updateChanges={setEditorText}
              setErrors={handleSetError}
              setIsChanged={() => setIsSingleConfigChanged(true)}
              isClearChangedElements={!isSingleConfigChanged}
              clearMarkers={isSingleConfigChanged}
              id={0}
              removeIsChanged={() => setIsSingleConfigChanged(false)}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
})

export default SingleConfigContent