import { ResponsesHistoryStore } from "src/stores";
import * as JSZip from 'jszip';
import { LANGUAGE, parseDataByLang } from "./configLanguageHelper";

type ResultType = string;

const ZIP_FILE_TYPE = "application/x-zip-compressed";

export const uploadZIP = (event: Event) => {
  const reader = new FileReader();
  const read = (file: File) => reader.readAsDataURL(file);
  const parser = (result: ResultType) => result.replace("data:application/x-zip-compressed;base64,", '');

  return upload(reader, event, read, parser, ZIP_FILE_TYPE);
};

export const uploadFile = (event: Event, lang: LANGUAGE) => {
  const reader = new FileReader();
  const read = (file: File) => reader.readAsText(file);
  const parser = (result: ResultType) => parseDataByLang(result, lang);

  return upload(reader, event, read, parser);
};

const upload = (
    reader: FileReader,
    event: Event ,
    read: (file: File) => void,
    parser: (result: ResultType) => void,
    expectedFileType?: string
) => {
  const target= event.target as HTMLInputElement;
  let file: File = (target.files as FileList)[0];

  if (file) {
    read(file);

    return new Promise(function (resolve) {
      reader.onload = function () {
        try {
          if (expectedFileType && file.type !== expectedFileType) {
            throw new Error('')
          } else {
            resolve(parser(String(reader.result)));
          }
        } catch (e) {
          ResponsesHistoryStore.setResponsesHistory({
            action: "Invalid file format",
            status: 'ERROR'
          });
        }
      };
    });
  }
};

export const uploadFromDirectory = (event: Event) => {
  const zip = new JSZip();
  const target = event.target as HTMLInputElement;
  const files = target.files as FileList;
  if ((files).length) {
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i], { base64: true });
    }
    return zip.generateAsync({ type: 'base64' }).then((content: string) => {
      return content
    });
  }
};