import axios from 'axios';
import { DEFAULT_HOST, DEV_PORTAL_HOSTS, EMPTY_TOKEN, HOSTNAME, LOGIN_ERROR, TOKEN_NOT_IN_CACHE } from "../constants";
import { AuthStore } from '../stores';

class Http {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: DEV_PORTAL_HOSTS[HOSTNAME] || DEFAULT_HOST,
    });
    this.instance.interceptors.response.use((response) => {
      return response
    }, this.handleError);
  }

  handleError = (err) => {
    if (err) {
      if (err.response) {
        const { message } = err.response?.data?.error;

        if (message === EMPTY_TOKEN || message === TOKEN_NOT_IN_CACHE || message === LOGIN_ERROR) {
          AuthStore.logout();
        }

        throw err.response && err.response.data;
      } else {
        throw err;
      }
    }
  };

  makeInstance() {
    return this.instance;
  }
}
export { Http };
export default new Http();