const { LANGUAGE } = require("src/helpers/configLanguageHelper");

export const layoutConfig =
  {
    "stat": {
      elementName: 'stat',
      headerName: 'Stats',
      specCompletions: [
        {
          path: "triggers.name",
          from: "trigger"
        },
        {
          path: "condition",
          from: "stat",
          specSymbol: 's.'
        }
      ],
      tableFields: ['name']
    },
    "unlock": {
      elementName: 'unlock',
      headerName: 'Unlocks',
      specCompletions: [
        {
          path: "stages.updStats.name",
          from: "stat"
        },
        {
          path: "stages.updStats.mode",
          from: "mode"
        },
        {
          path: "condition",
          from: "stat",
          specSymbol: 's.'
        }
      ],
      tableFields: ['name']
    },
    "mode": {
      elementName: 'mode',
      headerName: 'Modes',
      tableFields: ['name']
    },
    "table": {
      elementName: 'table',
      headerName: 'Tables',
      tableFields: ['name']
    },
    "trigger": {
      elementName: 'trigger',
      headerName: 'Triggers',
      tableFields: ['name'],
      lang: LANGUAGE.JS,
      isUseNamespace: false
    },
    "item": {
      elementName: 'item',
      headerName: 'Items',
      tableFields: ['itemdefid', 'name_english'],
      csvEditable: ['bundle']
    }
  }