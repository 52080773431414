import { observer } from "mobx-react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Flex,
  Input,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  InfiniteLoader,
  Table,
  Column,
  AutoSizer,
  defaultTableRowRenderer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized';
import 'react-virtualized/styles.css'
import { UserManagementStore } from "src/stores";
import "./style.scss";
import { JSONTree } from "react-json-tree";
import { themeMonokai } from "../themeMonokai";
import { Filter, NavArrowDown, NavArrowUp, Settings } from "iconoir-react";
import {
  COLUMNS,
  COLUMNS_WITH_FILTERS,
  DEFAULT_DETAILS_TEXT,
  HISTORY_BLOCK_HEIGHT,
  HISTORY_LOAD_LIMIT,
  HISTORY_TABLE_HEADER_HEIGHT,
  KEY_FOR_LOAD_DETAILS,
  KEY_FOR_TREE_ROOT,
  ROW_DEFAULT_HEIGHT,
  TIMESTAMP_COLUMN_NAME
} from "./constants";
import { formatCreateTimeUTC } from "src/helpers/formatCreateTime";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import Progress from "src/components/Progress";
import ChangeVisibilityForColumns from "src/components/ChangeVisibilityForColumns";
import CopyIcon from "../Icons/CopyIcon";
import { keys } from "lodash";
import ChangeVisibilityIcon from "src/components/ChangeVisibilityForColumns/ChangeVisibilityIcon";

const HistoryInfiniteLoader = observer(({isOpened}) => {
  const { getUserHistory, putToStorage, getFromStorage } = UserManagementStore;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [startPos, setStartPos] = useState('');
  const [hiddenColumns, setHiddenColumns] = useState(getFromStorage('hiddenColumns') || []);
  const [filters, setFilters] = useState(COLUMNS_WITH_FILTERS);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [openedFilter, setOpenedFilter] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [rowsDetails, setRowsDetails] = useState({});

  const cache = useMemo(() => new CellMeasurerCache({
    fixedWidth: true,
    minHeight: ROW_DEFAULT_HEIGHT
  }), []);

  const cellDataRender = (cellData) => {
    return cellData.dataKey === TIMESTAMP_COLUMN_NAME
      ? formatCreateTimeUTC(cellData.cellData)
      : cellData.cellData
  };

  const updateHiddenColumns=(result) => {
    putToStorage('hiddenColumns', result);
    setHiddenColumns(result);
  }

  const columns = (width) => COLUMNS.map(column => {
    const columnKey = column.key;
    return hiddenColumns.includes(columnKey)
      ? null
      : <Column
          label={column.label}
          dataKey={columnKey}
          width={width * 0.5}
          cellRenderer={cellDataRender}
          headerRenderer={(header) => (
            <>
              <Flex className="column-header">
                {header.label}
                <ChangeVisibilityIcon
                  hiddenColumns={hiddenColumns}
                  updateHiddenColumns={updateHiddenColumns}
                  column={column}
                  ml={1}
                />
                {keys(filters)?.includes(columnKey)
                  ? <Box ml={1}>
                      <Tooltip label="Open filter">
                        <Filter
                          cursor="pointer"
                          onClick={() => {
                            !openedFilter.includes(columnKey)
                              ? setOpenedFilter([...openedFilter, columnKey])
                              : setOpenedFilter([...openedFilter.filter(key => key !== columnKey)])
                          }}
                        />
                      </Tooltip>
                    </Box>
                  : null
                }
              </Flex>
              <Collapse
                in={openedFilter.includes(columnKey)}
                animateOpacity
                className="filter-collapse-block"
              >
                <Input
                  value={filters[columnKey]}
                  onChange={(e) => handleFilterChange(columnKey, e.target.value)}
                  placeholder="Filter value"
                />
              </Collapse>
            </>
          )}
        />
  })

  const loadHistory = useCallback(async (startPos) => {
    setIsLoading(true);
    try {
      const result = await getUserHistory(HISTORY_LOAD_LIMIT, startPos);

      if (result?.length > 0) {
        setData((prevData) => [...prevData, ...result]);
        setStartPos(result[result.length - 1].id);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [getUserHistory]);

  useEffect(() => {
    if (isOpened) {
      loadHistory();
    } else {
      setData([]);
    }
  }, [loadHistory, isOpened]);

  const isRowLoaded = ({ index }) => {
    const rowLoaded = !!data[index];
    return rowLoaded;
  };

  const loadMoreRows = () => {
    if (!isLoading && hasMore) {
      return loadHistory(startPos);
    }
    return Promise.resolve();
  };

  const tableRef = useRef();

  const DetailsComponent = observer(({data, handleResize}) => {
    const [isLoading, setIsLoading] = useState(true);
    const { getUserHistoryDetails } = UserManagementStore;

    const { id, service } = data;
    const isLoadedDetails = rowsDetails[id];

    const shouldExpandNodeInitiallyValue = (keyPath) => {
      if (keyPath[0] === KEY_FOR_LOAD_DETAILS || keyPath[0] === KEY_FOR_TREE_ROOT) {
        return keyPath
      }
    };

    const labelRendererValue = (keyPath) => {
      const keyName = keyPath[0];
      const dataWithDetails = isLoadedDetails ? data : {...data, details: "not requested"};
      return (
        <span
          className="label-block"
          onClick={() => {
            if (keyName === KEY_FOR_LOAD_DETAILS && !isLoadedDetails) {
              setIsLoading(false);
              getUserHistoryDetails(service, id).then(res => {
                setRowsDetails({...rowsDetails, [id]: res.details});
                setIsLoading(true);
                handleResize()
              })
            }
          }}
        >
          {keyName === KEY_FOR_LOAD_DETAILS && !isLoadedDetails
            ? <span className="details-icon">▶ </span>
            : null}
          {keyName}
          {keyName === KEY_FOR_TREE_ROOT ? <CopyIcon data={dataWithDetails}/> : null}
        </span>)
    }
    return (
      <JSONTree
        data={data}
        theme={themeMonokai}
        invertTheme={false}
        shouldExpandNodeInitially={shouldExpandNodeInitiallyValue}
        labelRenderer={labelRendererValue}
        valueRenderer={(raw, _, key) => {
          return <Box as="span" flex='1' textAlign='left' position="relative" className="service-name-block">
            <span className="service-name-block__text">{raw}</span>
            <span className="service-name-block__progress">
              {key === KEY_FOR_LOAD_DETAILS && !isLoading ? <Progress size={5} /> : null}
            </span>
          </Box>
        }}
      />
    )
  })

  const resizeRowHeight = useCallback((rowIndex) => {
    setTimeout(() => {
      cache.clear(rowIndex, 0);
      if (tableRef.current) {
        tableRef.current.recomputeRowHeights(rowIndex);
        tableRef.current.forceUpdateGrid()
      }
    }, 0)
  }, [cache]);

  const filteredData = useMemo(() => {
    return data.filter(row => {
      return keys(filters).every(column => {
        return row[column].toString().toLowerCase().includes(filters[column].toLowerCase())
      })
    })
  }, [data, filters]);



  const toggleRowExpansion = useCallback((id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }, []);


  const rowRenderer = useCallback((props) => {
    const { index, key, parent, style, rowData } = props;
    const { id } = rowData;
    const isExpanded = expandedRows[id];
    const data = {...rowData, details: rowsDetails[id] || DEFAULT_DETAILS_TEXT};

    const handleResize=(index, measure) => {
      resizeRowHeight(index)
      setTimeout(measure, 0)
    }

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => {
          setTimeout(measure, 0)
          return <div style={style}>
            <Flex>
              <Box
                cursor="pointer"
                onClick={() => {
                  toggleRowExpansion(rowData.id)
                  handleResize(index, measure)
                }}
              >
                {isExpanded
                  ? <NavArrowDown />
                  : <NavArrowUp />
                }
              </Box>
              {defaultTableRowRenderer({...props, style: { top: 0}})}
            </Flex>
            {isExpanded && (
              <div className="row-block">
                <DetailsComponent
                  data={data}
                  handleResize={() => handleResize(index, measure)}
                />
              </div>
            )}
          </div>
        }}
      </CellMeasurer>
  )}, [expandedRows, cache, toggleRowExpansion, resizeRowHeight, rowsDetails]);

  const rowGetter = ({ index }) => _getDatum(index);

  const _getDatum = index => filteredData[index % filteredData.length];

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value
    }));
  }

  const recomputeRowHeights = useCallback(() => {
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.recomputeRowHeights();
        tableRef.current.forceUpdateGrid()
      }
    }, 1)
  }, []);

  useEffect(() => {
    recomputeRowHeights();
  }, [filteredData, recomputeRowHeights]);

  const handleRowsRendered = (info) => {
    keys(expandedRows).forEach(row => {
      const index = filteredData.findIndex(element => element.id === row);
      if (index >= info.overscanStartIndex && index <= info.overscanStopIndex) {
        recomputeRowHeights();
      }
    })
  }

  return (
    <>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
          <Box as="span" flex='1' textAlign='left' position="relative" className="service-name-block">
            <span className="service-name-block__text"> User's history</span>
            <span className="service-name-block__progress">{isLoading ? <Progress size={5} /> : null}</span>
          </Box>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel display="flex">
        <Box className="history-block" style={{height: HISTORY_BLOCK_HEIGHT}}>
          {data.length
            ? <>
                <Flex justifyContent="end">
                  <Flex alignItems="center">
                    <Button mr={2} mb={1} onClick={loadMoreRows} isDisabled={!hasMore}>Load more</Button>
                  </Flex>
                  <ButtonWithTooltip
                    size="sm"
                    ml={1}
                    tooltipValue="Change visibility for history fields"
                    onClick={onToggle}
                  >
                    <Settings />
                  </ButtonWithTooltip>
                </Flex>
                <ChangeVisibilityForColumns
                  hiddenColumns={hiddenColumns}
                  columns={COLUMNS}
                  isOpen={isOpen}
                  fieldName="key"
                  updateHiddenColumns={updateHiddenColumns}
                  onClose={onClose}
                />
              </>
            : null
          }
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={hasMore ? data.length + 1 : data.length}
          >
            {({ onRowsRendered, registerChild }) => {
              const combineRef = (ref) => {
                tableRef.current = ref;
                registerChild(ref)
              }
              if (!data.length && !isLoading) {
                return <div>History is empty</div>
              }
              return <AutoSizer>
                {({ height, width }) => {
                  return <Table
                    ref={combineRef}
                    width={width}
                    height={height}
                    headerHeight={HISTORY_TABLE_HEADER_HEIGHT}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight}
                    rowCount={filteredData.length}
                    rowGetter={rowGetter}
                    rowRenderer={rowRenderer}
                    onRowsRendered={(info) => {
                      onRowsRendered(info);
                      handleRowsRendered(info);
                    }}
                    registerChild={registerChild}
                  >
                    {columns(width)}
                  </Table>
                }}
              </AutoSizer>
            }}
          </InfiniteLoader>
        </Box>
      </AccordionPanel>
    </>
  )
})

export default HistoryInfiniteLoader;