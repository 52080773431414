import { observer } from "mobx-react";
import { useMemo, useRef } from "react";
import { MultiSelect } from 'chakra-multiselect';
import { Box, Collapse } from "@chakra-ui/react";
import { useOutsideClick } from '@chakra-ui/react'
import './style.scss'

const ChangeVisibilityForColumns = observer(({
  hiddenColumns,
  columns,
  isOpen,
  fieldName = 'Header',
  updateHiddenColumns,
  onClose,
  className
}) => {
  const collapseBlockRef = useRef();

  useOutsideClick({
    ref: collapseBlockRef,
    handler: () => onClose(),
  })

  const servicesOptions = useMemo(() => {
    if (!columns.length) {
      return []
    }
    return columns.map((header, index) => {
      const headerValue = header[fieldName];
      return {id: index, value: headerValue, label: headerValue}
    })
  }, [columns, fieldName]);

  const handlerChangeVisibilityColumns = (_, change) => {
    let result;
    if (change.action === "multiRemove") {
      result = [...hiddenColumns, change.value];
    }
    if (change.action === 'multiSelect') {
      result = hiddenColumns.filter(column => column !== change.value.value);
    }
    if (change.action === "multiClear") {
      result = columns.map(el => el[fieldName]);
    }
    updateHiddenColumns(result);
  }

  const visibleColumns = useMemo(() =>
    columns.filter(column => !hiddenColumns?.includes(column[fieldName])).map(el => el[fieldName]),
  [columns, hiddenColumns, fieldName]);

  return (
    <Box className={`change-visibility-block ${className}`}>
      <Collapse
        in={isOpen}
        animateOpacity
        ref={collapseBlockRef}
      >
        <Box
          mt={1}
          rounded='md'
          shadow='md'
          className="multi-select-block"
          p={1}
        >
          <MultiSelect
            options={servicesOptions}
            value={visibleColumns}
            onChange={handlerChangeVisibilityColumns}
          />
        </Box>
      </Collapse>
    </Box>
  )
})

export default ChangeVisibilityForColumns