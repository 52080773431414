import { flow, makeObservable, observable, action } from "mobx";
import { GamesStore } from ".";
import userManagementApi from "../api/userManagement";
import Storages from "src/services/Storages";
import { GET_USER_PROFILE_ERROR_MESSAGE } from "src/components/Main/UserManagement/constants";

class UserManagementStore {
  constructor() {
    this.userid = 0;

    this.services = [];
    this.servicesWithHistory = [];
    this.data = {};
    this.actions = {};
    this.userInfo = {};
    this.reloadServicesAfterAction = [];
    this.userServiceError = {};
    this.showWatchedBlock = !!this.getFromStorage('watchedBlockHeight') || false;

    makeObservable(this, {
      clearUserProfile: action.bound,
      userid: observable,
      loadUserInfoAndServices: flow.bound,
      services: observable,
      servicesWithHistory: observable,
      getUserProfile: flow.bound,
      data: observable,
      getServiceApi: flow.bound,
      callServiceApi: flow.bound,
      actions: observable,
      userInfo: observable,
      putToStorage: action.bound,
      getFromStorage: action.bound,
      userManagementInfoForStorage: action.bound,
      getAllStorageData: action.bound,
      reloadServicesAfterAction: observable,
      setReloadServicesAfterAction: action.bound,
      reloadService: action.bound,
      showWatchedBlock: observable,
      setShowWatchedBlock: action.bound,
      getUserHistory: flow.bound,
      getUserHistoryDetails: flow.bound
    });
  }

  *loadUserInfoAndServices( typeForRequest, requestData ) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield userManagementApi.getUserInfoAndServices({
        projectid,
        environment,
        [typeForRequest]: typeForRequest === 'userid' ? Number(requestData) : requestData
      });
      this.services = data?.services || [];
      this.servicesWithHistory = data?.servicesWithHistory || [];
      const reloadServicesAfterAction =  this.getFromStorage('reloadServicesAfterAction');
      this.reloadServicesAfterAction = reloadServicesAfterAction?.length
        ? reloadServicesAfterAction
        : this.services;
      const userInfo = data?.userInfo;
      if (userInfo) {
        if (userInfo.error) {
          throw userInfo.error
        }
        const userid = userInfo?.data.id || 0;
        this.userid = userid;
        this.userInfo = userInfo
      }
    } catch (e) {
      this.services = [];
      return e
    }
  }


  *getUserProfile( service ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield userManagementApi.getUserProfileForService({
        projectid,
        environment,
        service,
        userid: this.userid
      });

      this.data = {
        ...this.data,
        [service]: data
      }

      if (this.data?.error) {
        this.userServiceError[service] = `${GET_USER_PROFILE_ERROR_MESSAGE} see details in console`
      } else {
        if (this.userServiceError[service]) {
          this.userServiceError[service] = ''
        }
      }
    } catch (e) {
      console.log(e)
      this.userServiceError[service] = `${GET_USER_PROFILE_ERROR_MESSAGE} ${e.error?.message || ''}`
      return e
    }
  }


  *getServiceApi( service ) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield userManagementApi.getServiceApi({
        projectid,
        environment,
        service,
        userid: this.userid
      });

      this.actions = {
        ...this.actions,
        [service]: data
      }
    } catch (e) {
      return e
    }
  }

  *callServiceApi( service, action, actionParams ) {
    const { projectid, environment } = GamesStore;
    try {
      yield userManagementApi.callServiceApi({
        projectid,
        environment,
        userid: this.userid,
        service,
        action,
        actionParams
      });

      this.reloadServicesAfterAction.forEach(reloadService => {
        this.reloadService(reloadService)
      })

    } catch (e) {
      return e
    }
  }

  userManagementInfoForStorage() {
    const { projectid, environment } = GamesStore;
    return `user-management-info_${projectid}_${environment}`;
  }
  putToStorage(name, value) {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();

    Storages.put(
      USER_MANAGEMENT_INFO_CURRENT,
      {...Storages.get(USER_MANAGEMENT_INFO_CURRENT), [name]: value}
    );
  }

  getFromStorage(name) {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();

    return Storages.get(USER_MANAGEMENT_INFO_CURRENT)
      ? Storages.get(USER_MANAGEMENT_INFO_CURRENT)[name]
      : null;
  }

  getAllStorageData() {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();
    return Storages.get(USER_MANAGEMENT_INFO_CURRENT)
  }

  setReloadServicesAfterAction(service, isReload) {
    const savedReloadServices = this.getFromStorage('reloadServicesAfterAction') || this.services;
    this.reloadServicesAfterAction = isReload
      ? [...savedReloadServices, service]
      : savedReloadServices.filter(savedService => savedService !== service);
    this.putToStorage('reloadServicesAfterAction',  this.reloadServicesAfterAction);
  }

  reloadService(service) {
    this.getUserProfile(service);
  }

  setShowWatchedBlock(value) {
    this.showWatchedBlock = value
  }

  *getUserHistory(index, startPos) {
    const { projectid, environment } = GamesStore;

    const getUserServiceHistory = (service) => {
      return userManagementApi.getUserHistory({
        projectid,
        environment,
        service,
        userid: this.userid,
        count: index,
        startPos
      }).then(res => {
        return res?.data?.entries || []
      })
      .catch(err => err)
    }

    try {
      const response = yield Promise.all(this.servicesWithHistory.map(service => {
        return getUserServiceHistory(service).then(res => {
          return res.length ? res.map(entrie => ({...entrie, service: service})) : []
        })
      }));

      return response.flat().sort((a, b) => b.timestamp - a.timestamp)
    } catch (e) {
      return e
    }
  }

  *getUserHistoryDetails(service, id) {
    const { projectid, environment } = GamesStore;

    try{
      const { data } = yield userManagementApi.getUserHistoryDetails({
        projectid,
        environment,
        service,
        userid: this.userid,
        id
      });

      return data;
    } catch(e) {
      return e
    }
  }

  clearUserProfile() {
    this.services = [];
    this.data = {};
    this.actions = {};
    this.userInfo = {};
    this.reloadServicesAfterAction = [];
  }
}

export default new UserManagementStore();