import { Box, Button, Divider, Heading, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ServicesInfoTable from "./ServicesInfoTable";
import { ServicesInfoStore, GamesStore } from "../../../stores";
import Progress from "src/components/Progress";

const ServicesInfo = observer(() => {
  const {
    loadServicesInfo,
    servicesInfo,
    loadPortalServerVersion,
    serverVersion,
    registerConfigs
  } = ServicesInfoStore;
  const { projectid, environment, games_loaded } = GamesStore;

  const [isLoadInitService, setIsLoadInitService] = useState(false);

  const handleRegisterConfigs = async () => {
    setIsLoadInitService(true);
    await registerConfigs();
    setIsLoadInitService(false);
  }

  useEffect(() => {
    if (games_loaded) {
      loadServicesInfo();
      loadPortalServerVersion();
    }
  }, [loadServicesInfo, projectid, environment, loadPortalServerVersion, games_loaded]);

  return (
    <Box>
      <Heading as="h3" size="lg">
        Services Info
      </Heading>
      <Divider orientation="horizontal" />
      <Box mt={3}>
        <ServicesInfoTable
          servicesInfoData={servicesInfo}
        />
      </Box>
      <Heading as="h3" size="lg" mt={2}>
        Services actions
      </Heading>
      <Button
        size='xs'
        colorScheme='green'
        variant='solid'
        onClick={handleRegisterConfigs}
      >
        Init services
      </Button>
      {isLoadInitService
        ? <Progress isFullScreen={isLoadInitService} />
        : null
      }
      <Box mt={5}>
        <Text fontSize="xs">UI version: {process.env.UI_VERSION}</Text>
        <Text fontSize="xs">Server version: {serverVersion}</Text>
      </Box>
    </Box>
  );
});

export default ServicesInfo;