import { ThemeTypings } from "@chakra-ui/react";

export const DEV_PORTAL_HOSTS = {
  "dev-portal-next.gaijin.ops": "http://dev-portal-gj-msk-01.gaijin.ops:7777",
  "dev-portal-next.gaijin.net": "https://dev-portal.gaijin.net/rpc",
  "dev-portal.loc": "http://127.0.0.1:7777",
  "localhost": "http://dev-portal-gj-msk-01.gaijin.ops:7777"
};

export const HOSTNAME = window.location.hostname;
export const DEFAULT_HOST = `/rpc`;

export const EMPTY_TOKEN = "EMPTY_TOKEN";
export const TOKEN_NOT_IN_CACHE = "TOKEN NOT IN CACHE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const NO_GAME_WITH_PERMISSIONS_ERROR = "NO_GAME_WITH_PERMISSIONS";
export const GAIJIN_CENTRAL_URL = "https://central-admin.gaijin.net/";

// validation error messages
export const ERROR_FOR_NON_EXISTENT_FIELD = 'This field does not exist';
export const ERROR_DUPLICATE_KEYS = 'Duplicate keys';
export const ERROR_FOR_INVALID_VALUES = 'This field has invalid value';
export const ERROR_FOR_NOT_UNIQUE_CONFIG_ELEMENT = 'must be unique';

export const UNDEFINED_STATUS = 'UNDEFINED';
export const SUCCESS_STATUS = 'SUCCESS';
export const WARNING_STATUS = 'WARNING'
export const ERROR_STATUS = 'ERROR';
export const CRITICAL_ERROR_STATUS = 'CRITICAL';
export const INVALID_ERROR_STATUS = 'INVALID';
export const VALID_STATUS = 'VALID';
export const STATUSES_FOR_REQUEST_ERRORS = [WARNING_STATUS, INVALID_ERROR_STATUS];

export const DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm:ss';

export const STATUS_RED_COLOR = "rgba(65, 11, 11, 1)";
export const STATUS_GREEN_COLOR = "rgba(23, 53, 27, 1)";
export const STATUS_BLUE_COLOR = "rgba(16, 34, 62, 1)";
export const STATUS_YELLOW_COLOR = "rgba(241, 196, 15, 0.5)";
export const DEFAULT_BG_COLOR = "#1A202C";

export const DEFAULT_SORTIG_COLUMN_FOR_TABLE = [{
  id: 'create_t',
  desc: true
}]

export const NOT_REMOVE_FROM_STORE = ['environment', 'current_pathname', 'projectid'];

export const DEFAULT_LINK = "/servicesInfo";

export const TYPE_NAME_FOR_BOOL = 'boolean';

export const TAG_COLORS_FROM_THEME: Record<string, ThemeTypings['colorSchemes']> = {
  production: 'red',
  test: 'green',
  staging: 'yellow',
  submission: 'blue',
  stable: 'purple',
};