import { observer } from "mobx-react";
import {Box, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import { useTable } from 'react-table';
import { useMemo } from "react";
import { entries } from "lodash";
import { COLUMNS_WIDTH, SERVICES_COLUMNS, URL_COLUMN_NAME } from "./constants";
import TextElementWithCopyButton from "src/components/TextElementWithCopyButton";
import Progress from "src/components/Progress";

const ServicesInfoTable = observer(({ servicesInfoData }) => {

  const servicesInfoTableData = useMemo(() => servicesInfoData
    ? entries(servicesInfoData).map(([key, value]) => ({
        char: key,
        ...value
      }))
    : [], [servicesInfoData]);

  const columns = useMemo(() => {
    return (
      SERVICES_COLUMNS.map((field) => ({
        Header: field,
        accessor: field
      })) || []
    );
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns,
    data: servicesInfoTableData.length ? servicesInfoTableData :  []
  });

  return (
    <Box minHeight={"150px"} position="relative">
      {servicesInfoData
        ? <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      key={column}
                    >
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => {
                      const columnName = cell.column.Header;
                      const isUrlColumn = columnName === URL_COLUMN_NAME;
                      return <Td
                        {...cell.getCellProps()}
                        key={`${cell.value}`}
                        w={COLUMNS_WIDTH[columnName] || 'auto'}
                      >
                        {isUrlColumn
                          ? <TextElementWithCopyButton
                              textValue={cell.value}
                              copyValue={cell.value}
                              label="Copy to buffer"
                              justifyContent="space-between"
                            />
                          : cell.render('Cell')
                        }
                      </Td>
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        : <Progress />
      }
    </Box>
  )
})

export default ServicesInfoTable