export const HISTORY_BLOCK_HEIGHT = "500px";
export const HISTORY_TABLE_HEADER_HEIGHT = 55;
export const DEFAULT_DETAILS_TEXT = 'click to load';

export const HISTORY_LOAD_LIMIT = 50;
export const KEY_FOR_LOAD_DETAILS = 'details';
export const KEY_FOR_TREE_ROOT = 'root';

export const COLUMNS  = [
  {key: 'service', label: 'Service'},
  {key: 'id', label: 'id'},
  {key: 'timestamp', label: 'Time (UTC)'},
  {key: 'tag', label: 'tag'},
  {key: 'transactId', label: 'transactId'}
];

export const TIMESTAMP_COLUMN_NAME = 'timestamp';

export const COLUMNS_WITH_FILTERS = {
  tag: '',
  service: ''
}

export const ROW_DEFAULT_HEIGHT = 50;