import { observer } from "mobx-react";
import { Tooltip, Box } from "@chakra-ui/react";
import { EyeOff } from "iconoir-react";

const ChangeVisibilityIcon = observer(({
  hiddenColumns,
  updateHiddenColumns,
  column,
  ml
}) => {

  return (
    <Box ml={ml}>
      <Tooltip label="Change visibility">
        <EyeOff
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            const hiddenColumnsUpdate = [...hiddenColumns];
            if (!hiddenColumns?.includes(column.Header)) {
              hiddenColumnsUpdate.push(column.Header)
            }
            updateHiddenColumns(hiddenColumnsUpdate)
          }}
        />
      </Tooltip>
    </Box>
  )
})

export default ChangeVisibilityIcon