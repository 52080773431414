import { Alert, AlertIcon } from '@chakra-ui/react';
import { observer } from "mobx-react";
import * as Diff2Html from "diff2html";
import { get, isEqual } from "lodash";
import './style.scss';
import { useState, useMemo, useEffect } from 'react';
import * as React from 'react';
import { getUnifiedDiffFromJsDiff } from '../getUnifiedDiffFromJsDiff';
import { Diff2HtmlOptionsType, ReactDiff2HtmlType, SelectEventType } from './types';

const DIFF_SIZE_SEPARATOR_CHAR = '@@';

const defaultOptions: Diff2HtmlOptionsType = {
  originalFileName: "Unknown-File-Name",
  updatedFileName: "Unknown-File-Name",
  inputFormat: "diff",
  outputFormat: "side-by-side",
  fileContentToggle: true,
  showFiles: true,
  summary: 'closed',
  matchingMaxComparisons: 100000,
  maxLineSizeInBlockForComparison: 20000,
  fileListToggle: true,
  fileListStartVisible: false,
  drawFileList: false,
  rawTemplates: {
    "generic-block-header": '',
    "generic-file-path": ''
  }
};

const constextOptions = ["4","10","20","50", "Infinity"];

const getDiffSize = (formatedStr: string): number[] => {
  const firstIndex = formatedStr.indexOf(DIFF_SIZE_SEPARATOR_CHAR);
  const lastIndex = formatedStr.lastIndexOf(DIFF_SIZE_SEPARATOR_CHAR);
  const sectionDiffSizes = formatedStr.substring(firstIndex + 2, lastIndex - 1).split(' ');

  const sizeValues: number[] = [];
  sectionDiffSizes.forEach(char => {
    const charSplit = char.split(',');
    if (char && charSplit?.length >= 1) {
      sizeValues.push(+charSplit[1]);
    }
  });
  return sizeValues;
}


const SelectContext = observer(({changeSelect}: {changeSelect: (event: SelectEventType) => void }) => {
  return (
    <div className='change-context-block'>
      <div>Context string count: </div>
      <select id="change-context-select" onChange={(event) => changeSelect(event)}>
        {constextOptions.map(optionValue => (
          <option key={optionValue}>{optionValue}</option>
        ))}
      </select>
    </div>
  );
})

const ReactDiff2Html = observer(({
  past = "",
  current = "",
  options = {},
  setIsLoad
}: ReactDiff2HtmlType) => {
  const [context, setContext] = useState(constextOptions[0]);
  const [isChangedContext, setIsChangedContext] = useState(false);

  const [diffStr, setDiffStr] = useState('')

  useEffect(() => {
    setIsChangedContext(false)
  }, [past, current]);

  if (typeof past !== "string") throw new Error("past must be a string");
  if (typeof current !== "string") throw new Error("current must be a string");
  const equal = isEqual(past, current);


  const isHideCommonLines = useMemo(() => (str: string) => {
    const pathLinesCount = past.split('\n').length;
    return pathLinesCount > getDiffSize(str)[0];
  }, [past]);

  if(!equal) {
    const useOptions = { ...defaultOptions, ...options };

    const oldFileName = get(useOptions, "originalFileName", "");
    const newFileName = get(useOptions, "updatedFileName", "");

    const formatedStr = getUnifiedDiffFromJsDiff(
      oldFileName,
      newFileName,
      past,
      current,
      "",
      "",
      {context}
    );

    useEffect(() => {
      setDiffStr(Diff2Html.html(formatedStr, useOptions));
      setIsLoad && setIsLoad(true)
    }, [formatedStr, useOptions, setIsLoad])

    const handleSelectContextChange = (event: SelectEventType) => {
      const target = event.target as HTMLSelectElement;
      setContext(target.value);
      setIsChangedContext(true);
    }

    return (
      <div id='diff-wrapper'>
        <div
          className="diff-block"
          id="diff"
          dangerouslySetInnerHTML={{ __html: diffStr}}
        />
        {isChangedContext || isHideCommonLines(formatedStr)
          ? <SelectContext changeSelect={handleSelectContextChange} />
          : null}
      </div>
    )
  } else {
    return(
      <Alert status='info' variant='solid'>
        <AlertIcon />
        No configuration changes
      </Alert>
    )
  }
});

export default ReactDiff2Html;