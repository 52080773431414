export const themeMonokai = {
  scheme: 'monokai',
  base00: 'transparent',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#F9F8F5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#F9F8F5',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

export const jsonTreeStyles = {
  ...themeMonokai,
  valueLabel: () => ({
    style: {
      display: "none"
    }
  }),
  value: () => ({
    style: {
      margin: 0
    },
  })
};