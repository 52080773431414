import { observer } from 'mobx-react';
import { useCallbackStopNavigate } from '../../hooks/useCallbackStopNavigate';
import { ConfigsStore, DialogIsUnsavedChangesStore, SingleConfigStore } from '../../stores';
import Dialog from '../Dialog';

const DialogIsUnsavedChanges = observer(() => {
  const { isOpenDialog, handleConfirmDialog, clearDialogIsUnsavedChangesData} = DialogIsUnsavedChangesStore;
  const { clearConfigs, isConfigChanges } = ConfigsStore;
  const {isSingleConfigChanged, clearSingleConfig} = SingleConfigStore;

  const clearChanges = () => {
    clearConfigs();
    clearSingleConfig();
  }

  const [showDialog, confirmNavigation, cancelNavigation] =
    useCallbackStopNavigate(isConfigChanges || isSingleConfigChanged, clearChanges);

  const handleConfirm = () => {
    if (handleConfirmDialog) {
      handleConfirmDialog();
      clearDialogIsUnsavedChangesData()
    } else {
      confirmNavigation()
    }
  }

  const handleCancel = () => {
   cancelNavigation();
   clearDialogIsUnsavedChangesData();
  }

  return (
    <Dialog
      showDialog = {isOpenDialog || showDialog}
      cancel = {handleCancel}
      confirm = {handleConfirm}
      title='Leave page?'
      subtitle='Your changes have not been saved'
    />
  )
})
export default DialogIsUnsavedChanges