import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Input
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import * as moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DATE_TIME_FORMAT, DEFAULT_BG_COLOR } from "src/constants";
import CodeMirror from '@uiw/react-codemirror';
import { getConfigZIP, getConfigZIPAndUnpack } from "../../../helpers/downloadConfig";
import { uploadFromDirectory, uploadZIP } from "../../../helpers/uploadConfig";
import { GamesStore, PermissionsStore, ScriptsStore } from "../../../stores";
import { EditorView } from "@codemirror/view";
import './style.scss';
import { materialDark } from 'cm6-theme-material-dark';
import { javascript } from "@codemirror/lang-javascript";
import { JSONTree } from "react-json-tree";
import { jsonTreeStyles } from "../UserManagement/themeMonokai";
import { activeLineColor, cursorType } from "src/helpers/themeExtension";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import { keys } from "lodash";
import SingleConfig from "../SingleConfig";

const Scripts = observer((props) => {
  const { elementName } = props;
  const canChangeProfileScripts = useMemo(() => `can_change_${elementName}s`, [elementName]);
  const canViewProfileScripts = useMemo(() => `can_view_${elementName}s`, [elementName]);
  const {
    loadScriptsArchiveInfo,
    uploadScriptsConfig,
    scriptsArchiveInfo,
    loadScriptsConfig,
    checkScripts
  } = ScriptsStore;
  const { permissions } = PermissionsStore;
  const { projectid, environment, games_loaded } = GamesStore;
  const [configs, setConfigs] = useState([]);
  const uploadFiles = useRef();
  const uploadFile = useRef();

  const loadScriptsConfigAsync = useCallback(async() => {
    return await loadScriptsConfig(elementName);
  }, [loadScriptsConfig, elementName])

  useEffect(() => {
    setConfigs([])
  }, [projectid, environment]);

  const unpackScript = (data) => {
    getConfigZIPAndUnpack(data).then(configs => {
      setConfigs(configs)
    })
  }

  useEffect(() => {
    if (permissions?.includes(canChangeProfileScripts)) {
      loadScriptsConfigAsync().then(scriptsConfig => {
        unpackScript(scriptsConfig)
      })
    }
  }, [loadScriptsConfigAsync, permissions, canChangeProfileScripts])

  const getConfig = async () => {
    loadScriptsConfigAsync().then(scriptsConfig => {
      if (scriptsConfig) {
        getConfigZIP( scriptsConfig );
      }
    })
  };

  const setConfig = (event) => {
    uploadZIP( event ).then((data) => {
      uploadScriptsConfig(data, elementName);
      loadScriptsArchiveInfo(elementName);
      unpackScript({archive: data});
    }).finally(() => {
      uploadFile.current.value = '';
    });
  };

  const setConfigFromDirectory = (event) => {
    uploadFromDirectory(event).then(data => {
      uploadScriptsConfig(data, elementName);
      uploadFiles.current.value = '';
      loadScriptsArchiveInfo(elementName);
      unpackScript({archive: data});
    });
  }

  useEffect(() => {
    if (games_loaded) {
      loadScriptsArchiveInfo(elementName);
    }
  }, [loadScriptsArchiveInfo, elementName, projectid, environment, games_loaded]);

  const upload_timestamp = scriptsArchiveInfo?.upload_timestamp
    ? moment(scriptsArchiveInfo?.upload_timestamp*1000).format(DATE_TIME_FORMAT)
    : '';

  const codeMirrorNumbersPudding = EditorView.theme({
    ".cm-line": { paddingLeft: "10px" }
  });


  return (
    <Box>
      <Heading as="h3" size="lg">
        Scripts
      </Heading>
      <Divider orientation="horizontal" />
      <Box className="scripts-top-block" backgroundColor={DEFAULT_BG_COLOR}>
        {keys(scriptsArchiveInfo).length
          ? <Heading as="h6" size="xs" mb={3} mt={5} className="scripts-archive-info">
              <span>Upload timestamp:</span>
              <span className="scripts-archive-info__value">{upload_timestamp}</span>
              <div />
              <span>Sha1:</span>
              <span className="scripts-archive-info__value">{scriptsArchiveInfo?.sha1}</span>
            </Heading>
          : null
        }
        <Box className="scripts-buttons-block">
          <ButtonWithTooltip
            mt={4}
            variant="outline"
            textTransform="uppercase"
            accessPermission={canViewProfileScripts}
          >
            <Input
              type="file"
              aria-hidden="true"
              accept="application/zip"
              onChange={setConfig}
              ref={uploadFile}
            />
            Upload zip
          </ButtonWithTooltip>
          <ButtonWithTooltip
            mt={4}
            ml={5}
            variant="outline"
            textTransform="uppercase"
            onClick={getConfig}
            accessPermission={canChangeProfileScripts}
          >
            Download zip
          </ButtonWithTooltip>

          <ButtonWithTooltip
            mt={4}
            ml={5}
            variant="outline"
            textTransform="uppercase"
            accessPermission={canViewProfileScripts}
          >
            <Input
              type="file"
              aria-hidden="true"
              multiple="true"
              webkitdirectory="true"
              directory="true"
              onChange={setConfigFromDirectory}
              ref={uploadFiles}
            />
              Upload from directory
          </ButtonWithTooltip>
          <ButtonWithTooltip
            mt={4}
            ml={5}
            variant="outline"
            textTransform="uppercase"
            onClick={checkScripts}
          >
            Check scripts compilation
          </ButtonWithTooltip>
        </Box>
        <Divider mt={2} orientation="horizontal" />
      </Box>

      {keys(configs).length
        ? <JSONTree
            data={configs}
            theme={jsonTreeStyles}
            invertTheme={false}
            valueRenderer={(_, value, ...keyPath) => {
              return (
                <Accordion allowToggle className="tree-accordion">
                  <AccordionItem>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton>
                          <div className={`button-icon ${isExpanded ? "expanded" : ""}`}>▶</div>
                          {keyPath[0]}
                        </AccordionButton>
                        <AccordionPanel>
                          <CodeMirror
                            readOnly={true}
                            value={value}
                            key={keyPath}
                            theme={materialDark}
                            extensions={[
                              javascript(),
                              codeMirrorNumbersPudding,
                              cursorType,
                              activeLineColor
                            ]}
                          />
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              )
            }}
          />
        : null
      }
      <SingleConfig
        elementName="profile_config"
        headerName="Config"
        isUpload={true}
      />
    </Box>
  );
});

export default Scripts;
