import { Td, Tr, Checkbox } from '@chakra-ui/react';
import { Cancel, NavArrowDown, NavArrowUp, SaveFloppyDisk } from 'iconoir-react';
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import ButtonWithTooltip from 'src/components/ButtonWithTooltip';
import { STATUS_GREEN_COLOR, STATUS_RED_COLOR } from 'src/constants';
import { ConfigElementStore, ConfigsViewStore, ConfigsStore, SchemaStore } from "../../../stores";
import CodeEditor from '../../CodeEditor';
import ActionConfirmation from "../ActionConfirmation";
import { getConfigDataByLang } from 'src/helpers/configLanguageHelper';

const ConfigElementEditor = observer(({ setEditorText, index, specCompletionsPath, editorText, id }) => {
  const { schemaWithRef } = SchemaStore;
  const {
    getElementByIdx,
    setElementHasErrors,
    getElementData,
    setElementHasChanged,
    setRemoveIsChanged
  } = ConfigElementStore;

  const { configsChanges, updateUniqueValues } = ConfigsStore;

  const { fieldList, language, isJSONLang, csvEditable } = ConfigsViewStore;

  const configElement = getElementByIdx(index);
  const configElementData = getElementData(index);

  return <CodeEditor
    data={configElementData}
    schema={schemaWithRef}
    updateChanges={setEditorText}
    setErrors={setElementHasErrors}
    setIsChanged={setElementHasChanged}
    fieldListForComplite={fieldList}
    specCompletionsPath={specCompletionsPath}
    changedLines={configsChanges.get(id)?.changedLines}
    isJSONLang={isJSONLang}
    lang={language}
    newData={editorText}
    isNewAddedElement={configElement?.isNewAddedElement}
    id={id}
    clearMarkers={configsChanges.get(id)?.isChanged}
    csvEditable={csvEditable}
    removeIsChanged={setRemoveIsChanged}
    onBlur={updateUniqueValues}
  />
})

const ConfigsTableElement = observer(({
  elementName,
  row,
  visibleColumns,
  staticColumns,
  specCompletionsPath
}) => {
  const { jsonErrors, configsChanges } = ConfigsStore;
  const { checkedConfigs, setConfigChecked, removeExpandedElement, language } = ConfigsViewStore;

  const {
    addElement,
    getElementByIdx,
    removeElement,
    changeElement,
    getElementDataByKey
  } = ConfigElementStore;

  const [editorText, setEditorText] = useState('');
  const [isLoadCodeEditor, setIsLoadCodeEditor] = useState(false);

  const rowKey = row.values.key;

  useEffect(() => {
    const configElement = getElementDataByKey(rowKey);
    const configElementChangedData = configsChanges.get(rowKey);
    if (configElement && row.isExpanded) {
      const newData = configElementChangedData?.isChanged
        ? configElementChangedData.changedData
        : getConfigDataByLang(language, configElement)

      setEditorText(newData);
      setIsLoadCodeEditor(true);
    }
  }, [rowKey, row, configsChanges, getElementDataByKey, language])


  const saveChanges = rowIndex => {
    if (editorText) {
      const element = getElementByIdx(rowIndex);
      if (!element.isNewAddedElement) {
        changeElement(rowIndex, editorText, elementName);
      } else {
        addElement(editorText, elementName, rowIndex);
      }
    }
  };

  const elementRemove = rowIndex => {
    removeElement(rowIndex, elementName);
    removeExpandedElement(rowIndex);
  }

  const isError = jsonErrors.get(rowKey)
  const isElementChangedFlag = configsChanges.get(rowKey)?.isChanged;

  const getRowColor = () => {
    if (isError) {
      return STATUS_RED_COLOR
    }
    if (isElementChangedFlag) {
      return STATUS_GREEN_COLOR
    }
    if (row.isExpanded) {
      return "whiteAlpha.200"
    }
    return 'inherit'
  };

  return (
    <Fragment key={`row_${rowKey}`}>
      <Tr
        {...row.getRowProps()}
        bg={getRowColor()}
      >
        <Td>
          <Checkbox
            isChecked={checkedConfigs.has(rowKey)}
            onChange={(e) =>
              setConfigChecked(rowKey, e.target.checked)
            }
          />
        </Td>
        <Td>
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ?
              <NavArrowUp /> :
              <NavArrowDown />
            }
          </span>
        </Td>
        {row.cells.map(cell => {
          return(
            <Td {...cell.getCellProps()}>
              {cell.render('Cell')}
            </Td>
          )
        })}
        <Td textAlign="end">
          <ButtonWithTooltip
            size='xs'
            colorScheme='green'
            marginRight='20px'
            isDisabled={!isElementChangedFlag || isError}
            onClick={() => saveChanges(row.index)}
            accessPermission={`can_change_${elementName}s`}
          >
            <SaveFloppyDisk />
          </ButtonWithTooltip>
          <ActionConfirmation
            size='xs'
            marginRight='0'
            variant='solid'
            handleClick={() => elementRemove(row.index)}
            dialogHeader={`Remove ${elementName}`}
            dialogBody={`Confirm ${elementName} removal?`}
            colorScheme='red'
            icon={<Cancel/>}
            accessPermission={`can_change_${elementName}s`}
          />
        </Td>
      </Tr>
      {row.isExpanded || isElementChangedFlag ? (
        <Tr display={row.isExpanded ? 'table-row' : 'none'}>
          <Td colSpan={visibleColumns.length + staticColumns}>
            {row.isExpanded && isLoadCodeEditor
              ? <ConfigElementEditor
                setEditorText={setEditorText}
                index={row.index}
                specCompletionsPath={specCompletionsPath}
                editorText={editorText}
                id={rowKey}
              />
              : null
            }
          </Td>
        </Tr>
      ) : null}
    </Fragment>
  )
})

export default ConfigsTableElement;