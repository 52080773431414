import {
  Box,
  Flex,
  Heading,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
  Tooltip,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { NavArrowLeft, NavArrowRight, Settings } from 'iconoir-react';
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePagination, useSortBy, useTable } from 'react-table';
import Progress from 'src/components/Progress';
import SortColumnIcon from 'src/components/SortColumnIcon';
import './style.scss';
import { COLUMNS_WITH_FIXED_WIDTH, DISABLE_FOR_SORTING_LB_FIELD } from './constants';
import { isUndefined } from 'lodash';
import ButtonWithTooltip from 'src/components/ButtonWithTooltip';
import { loadFromDataToCsvFile } from 'src/helpers/loadFromDataToCsvFile';
import { LeaderboardStore, MenuPageSelectorsStore } from 'src/stores';
import ChangeVisibilityIcon from 'src/components/ChangeVisibilityForColumns/ChangeVisibilityIcon';
import ChangeVisibilityForColumns from "src/components/ChangeVisibilityForColumns";

const TableCell = observer(({cell, getColumnStyle}) => {
  const cellContainer = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if(cellContainer.current) {
      const { current } = cellContainer;
      setShowTooltip(current.scrollWidth > current.clientWidth);
    }
  }, [cell])

  return (
    <Td
      textAlign={'center'}
      {...cell.getCellProps()}
      {...getColumnStyle(cell.column.Header)}
    >
      <Tooltip label={ showTooltip ? cell.value : ''}>
        <div className='text-container' ref={cellContainer}>
          {cell.render('Cell')}
        </div>
      </Tooltip>
    </Td>
  )
})

const LeaderboardTable = observer(({
  total,
  startIndex,
  elementsCount,
  isLoading,
  changePage,
  category,
  hiddenColumns,
  setHiddenColumnsBySelect
}) => {
  const controlledPageSize = elementsCount;
  const controlledPageCount = Math.ceil(total / controlledPageSize);
  const [top, setTop] = useState(0);
  const {
    lbPageDescription,
    currentlbPageData,
    setlbPageDescriptionByIndex,
    setLBSortBy,
    leaderboard
  } = LeaderboardStore;

  const { currentPageSelectorIndex } = MenuPageSelectorsStore;

  const { isOpen, onToggle, onClose } = useDisclosure();

  const updateHiddenColumns = useCallback((hiddenColumns) => {
    if (lbPageDescription[currentPageSelectorIndex]) {
      lbPageDescription[currentPageSelectorIndex].hiddenColumns = hiddenColumns;
      setlbPageDescriptionByIndex(currentPageSelectorIndex, lbPageDescription[currentPageSelectorIndex]);
    }
    setHiddenColumnsBySelect(hiddenColumns);
  }, [lbPageDescription, currentPageSelectorIndex, setlbPageDescriptionByIndex, setHiddenColumnsBySelect]);

  const columns = useMemo(() => {
    let values = [];

    if (leaderboard?.categories) {
      values = leaderboard.categories.map((field) => ({
        Header: field,
        accessor: field,
        disableSortBy: DISABLE_FOR_SORTING_LB_FIELD.includes(field),
      }));
    }

    return values;
  }, [leaderboard]);

  const data = useMemo(() => {
    let values = [];

    if (leaderboard?.categories) {
      values = leaderboard.users_data.map((value) =>
        Object.assign(...leaderboard.categories.map((n, i) => ({ [n]: value[i] })))
      );
    }

    return values;
  }, [leaderboard]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex, sortBy },
    setHiddenColumns,
    setSortBy
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: startIndex, pageSize: controlledPageSize, hiddenColumns },
    manualPagination: true,
    manualSortBy: true,
    pageCount: controlledPageCount,
  }, useSortBy, usePagination);

  const sortByFromStorage = useMemo(() => {
    return currentlbPageData?.sortBy ? [currentlbPageData.sortBy] : []
  }, [currentlbPageData]);

  useEffect(() => {
    if (sortByFromStorage) {
      setSortBy(sortByFromStorage)
    }
  }, [sortByFromStorage, setSortBy])

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns])

  // for reload canNextPage and canPreviousPage flags
  useEffect(() => {
    !isLoading && gotoPage(startIndex);
  }, [startIndex, gotoPage, isLoading]);

  const pageCountsArray = useMemo(() =>
    [...Array(pageCount).keys()].map(x => ++x), [pageCount]);

  const getTopForPaginationBlock = () => {
    const filtersElement = document.getElementById('leaderboard-filters');
    if (filtersElement) {
      setTop(filtersElement.clientHeight);
    }
  }

  useEffect(() => {
    getTopForPaginationBlock()
    window.addEventListener('resize', getTopForPaginationBlock);
    return () => {
      window.removeEventListener('resize', getTopForPaginationBlock);
    };
  }, [isLoading]);

  const getColumnStyle = (columnName) => ({
    w: COLUMNS_WITH_FIXED_WIDTH[columnName] || 'auto',
    maxW: COLUMNS_WITH_FIXED_WIDTH[columnName] || 'auto',
    minW: COLUMNS_WITH_FIXED_WIDTH[columnName] || 'auto',
    pl: 0,
    pr: 0,
  })

  const EmptyBlock = observer(() => {
    return <div style={{width: "7px"}}/>
  });

  const isDefaultCategory = (column, category) => {
    const { id: currentCategory } = sortBy[0] || {};
    return column.Header === category && isUndefined(column.isSortedDesc)
      && (!currentCategory || currentCategory === category) && !sortByFromStorage.length
  }

  return (
    <Box className='leaderboard-table-block'>
      <ChangeVisibilityForColumns
        hiddenColumns={hiddenColumns}
        columns={columns}
        isOpen={isOpen}
        updateHiddenColumns={updateHiddenColumns}
        onClose={onClose}
      />
      {!!total && (
        <Flex
          className='pagination-buttons'
          alignItems="center"
          justifyContent="space-between"
          top={`${top}px`}
        >
          <Heading as="h6" size="xs">
            Total leaders: {total}
          </Heading>
          <Box>
            {`Page `}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>
          </Box>
          <Flex alignItems="center" className='pagination-page-number'>
            <span>Select page:</span>
            <IconButton
              variant='outline'
              icon={<NavArrowLeft />}
              onClick={() => changePage(pageIndex - 1)}
              isDisabled={!canPreviousPage}
              mr={3}
              ml={3}
            />
            <Select
              value={pageIndex + 1}
              onChange={(e) => {
                const page = Number(e.target.value) - 1;
                gotoPage(page);
                changePage(page);
              }}
            >
              {pageCountsArray.map((el) => (
                <option
                  key={`pageIndex_${el}`}
                  value={el}
                >
                  {el}
                </option>
              ))}
            </Select>
            <IconButton
              variant='outline'
              icon={<NavArrowRight />}
              onClick={() => changePage(pageIndex + 1)}
              isDisabled={!canNextPage}
              ml={3}
            />
            <ButtonWithTooltip
              size="sm"
              ml="5px"
              tooltipValue="Change visibility for leaderboard fields"
              onClick={onToggle}
            >
              <Settings/>
            </ButtonWithTooltip>
            <Button ml={3} onClick={() => loadFromDataToCsvFile(data, 'leaderboard.csv')}>Export to csv</Button>
          </Flex>
        </Flex>
      )}
      <TableContainer className='leaderboard-table-container'>
        <Table {...getTableProps()} className='leaderboard-table'>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const isDefaultCategoryValue = isDefaultCategory(column, category);

                  const handleChangeSortBy = () => {
                    const sortValue = isDefaultCategoryValue ? false : !column.isSortedDesc;
                    if (lbPageDescription && currentlbPageData) {
                      currentlbPageData.sortBy =  {
                        id: column.id,
                        desc: sortValue
                      };
                      setlbPageDescriptionByIndex(currentPageSelectorIndex, currentlbPageData);
                      setLBSortBy(sortValue)
                    }
                    column.toggleSortBy(sortValue);
                  }


                  return <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...getColumnStyle(column.Header)}
                    onClick={handleChangeSortBy}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textTransform: 'none'
                    }}>
                      {column.canSort ? <EmptyBlock /> : null}

                      {column.render('Header')}
                      <SortColumnIcon
                        sortedIndex={isDefaultCategoryValue ? 0 : column.sortedIndex}
                        canSort={column.canSort}
                        isSortedDesc={isDefaultCategoryValue ? true : column.isSortedDesc}
                      />
                      {column.canSort && column.sortedIndex === -1 ? <EmptyBlock /> : null}
                      <ChangeVisibilityIcon
                        hiddenColumns={hiddenColumns}
                        updateHiddenColumns={updateHiddenColumns}
                        column={column}
                      />
                    </div>
                  </Th>
                })}
              </Tr>
            ))}
          </Thead>
          {!isLoading
            ? <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell cell={cell} getColumnStyle={getColumnStyle} />
                    ))}
                  </Tr>
                )
              })}
            </Tbody>
            : <Progress/>
          }
        </Table>
      </TableContainer>
    </Box>
  )
})

export default LeaderboardTable