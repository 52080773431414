import { flow, makeObservable, observable } from "mobx";
import SidebarMenuApi from "../api/sidebarMenu";
import { GamesStore } from ".";

class SidebarMenuStore {
  constructor() {
    this.enableConfigs = null;

    makeObservable(this, {
      enableConfigs: observable,
      getEnabledConfigs: flow.bound
    });
  }

  *getEnabledConfigs() {
    const { environment, projectid } = GamesStore;
    try {
      const { data } = yield SidebarMenuApi.getEnabledConfigs({ projectid, environment });
      this.enableConfigs = data;
      return data
    } catch (error) {
      this.configs = [];
    }
  }

}

export default new SidebarMenuStore();